import {timeFormat, numberFormat} from "@custom/index";
import {conversionAmount} from "@/utils/hooks";

//2022/03/27 王江毅 工单管理 工单列表
export function tableWorkOrderColumn(that) {
    return [
        {
            prop: "wordNum",
            label: "工单编号",
            width: 150
        },
        {
            label: "处理状态",
            width: 150,
            render: (h, {row}) => {
                const {state, wordType} = row;
                let stateName = "";
                if(wordType === '退房') {
                    switch (state) {
                        case 0:
                            stateName = "待发起";
                            break;
                        case 1:
                            stateName = "待片管员预审";
                            break;
                        case 2:
                            stateName = "待负责人审核";
                            break;
                        case 3:
                            stateName = "待财务审核";
                            break;
                        case 4:
                            stateName = "已完成";
                            break;
                    }
                }

                if(wordType === '换房') {
                    if (state === 2) stateName = "等待租赁部确认";
                    if (state === 3) stateName = "等待管家处理";
                    if (state === 6) stateName = "已完成";
                }

                return stateName;
            }
        },
        {
            label: "租客名字",
            width: 100,
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            label: "区域",
            prop: 'district'
        },
        {
            label: "房源地址",
            width: 200,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "house-resource", query: {data: {apartmerntName: apartmentName}}});
                        }
                    }
                }, apartmentName);
            }
        },
        {
            prop: "wordType",
            label: "业务类型",
            width: 100,
        },
        {
            prop: "submitter",
            label: "提交人",
            width: 100,
        },
        {
            label: "申请时间",
            render: (h, {row}) => {
                const {applyTime} = row;
                return applyTime ? timeFormat(new Date(row.applyTime)) : "";
            }
        },
        {
            label: "提交时间",
            width: 140,
            render: (h, {row}) => {
                const {createDate} = row;
                return createDate ? timeFormat(new Date(createDate), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
        {
            label: "处理时间",
            width: 140,
            render: (h, {row}) => {
                const {updateTime} = row;
                return updateTime ? timeFormat(new Date(updateTime), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
        {
            label: "总额",
            render: (h, {row}) => {
                const {total} = row;
                return total > 0 ? "收取" + numberFormat(total / 100) : "退还" + numberFormat((-total) / 100);
            }
        },
        {
            prop: "comment",
            label: "备注",
            showOverflowTooltip: true,
        },
    ];
}
export function tableTransferWorkOrderColumn(that) {
    return [
        {
            prop: "workOrderNum",
            label: "工单编号",
        },
        {
            label: "处理状态",
            prop: "statusName",
        },
        {
            label: "租客名字",
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            that.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            label: "业务类型",
            prop: 'workOrderTypeName'
        },
        {
            prop: "submitUserName",
            label: "申请人",
        },
        {
            label: "申请时间",
            render: (h, {row}) => {
                const {transferDate} = row;
                return transferDate ? timeFormat(new Date(row.transferDate)) : "";
            }
        },
        {
            label: "处理时间",
            render: (h, {row}) => {
                const {processingTime} = row;
                return processingTime ? timeFormat(new Date(processingTime), "yyyy-MM-dd HH:mm:ss") : "";
            }
        },
        {
            label: "转移金额",
            render: (h, { row }) => {
                const { transferRentalBalanceAmount, transferOtherBalanceAmount } = row;
                const totalAmount = (transferRentalBalanceAmount + transferOtherBalanceAmount) / 100;
                return numberFormat(totalAmount);
            }
        },
        {
            prop: "remark",
            label: "备注",
            showOverflowTooltip: true,
        },
    ];
}